.primary {
    background-color: #009dff;  
    text-align: center;
    color: #bebebe;
    font-family: 'Poppins';
    font-size: 50;
    width: 200; 
}

.background {
    background: 'linear-gradient( 180deg, rgba(121,194,243,1) 22.6%, turquoise 67.7% )'; 
    height: '100vh'; display: 'flex'; 
    flex-direction: 'row'; align-items: 'center';
}

/* 320 x 568 <-- smallest phone dimensions iPhone 5/SE
// #D81C35 Theme red
// #c9c9c9 Light gray
// #3C3C3C theme gray 
// #ee968d Fixed Salmon 
// #ffdab9 Peachpuff
// #ffe135 Banana #f9f586 Lighter Yellow
// #FF8A00 Tag Orange
// Blue pink grad 'linear-gradient( 114.2deg,  rgba(121,194,243,1) 22.6%, rgba(255,180,239,1) 67.7% )'
ORIGINAL: background: 'linear-gradient( 180deg, rgba(121,194,243,1) 22.6%, turquoise 67.7% )',
PROFESSIONAL: white to #cecece gradient with theme red header
*/